import React, { Component } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layouts';
import { Container } from '../components/styledComponents';
import { Title } from '../components/styledComponents/products';
import Seo from '../components/seo/page';
import Solutions from '../components/pages/main/Solutions';

export default class Contacts extends Component {
  componentDidMount() {
    document.body.style.background = '#fff';
  }

  render() {
    const { data } = this.props;
    const node = data.allMarkdownRemark.edges[0].node;
    return (
      <Layout>
        <Seo
          title={node.frontmatter.title}
          meta={{
            description: node.frontmatter.description,
            keywords: node.frontmatter.keywords,
            'og:url': 'solutions',
            'og:description': node.frontmatter.og_description,
          }}
        />
        <Container>
          <Title>Решения</Title>
          <Solutions data={node} single />
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query getSolutionsData {
    allMarkdownRemark(
      filter: { frontmatter: { page: { eq: "solutions" } } }
      sort: { fields: [frontmatter___main], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            page
            section
            title
            index
            main
            block
            keywords
            description
            og_description
          }
          html
        }
      }
    }
  }
`;
